@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $white;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: "SAASeriesDStandardD";
	text-transform: uppercase;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
	position: relative;
	min-height: 100vh;
	video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&::before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.25);
		z-index: 2;
	}
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

.btn{
	background-color: transparent;
	border: solid 1px $black;
	display: block;
	text-transform: uppercase;
	color: $black;
	padding: 10px 30px;
	font-size: 18px;
	text-align: center;
	@include hover-focus{
		background-color: $white;
		color: $black;
	}
	@include tablet-up{
		border: solid 2px $black;
		padding: 15px;
	}
}

h2,h3{
	margin: 0;
}

h2{
	font-family: "Swiss721BoldExtended";
}

.container{
	padding: 0 15px;
	@include tablet-up{
		padding: 0 30px;
	}
}

.social{
	gap: 15px;
	@include flex(row,center,center);
	flex-wrap: wrap;
	a{
		color: $white;
		font-size: 20px;
	}
}

.newsletter{
	form{
		font-size: 9px;
		@include flex(row,flex-start,initial);
		gap: 10px;
		label{
			display: none;
		}
		input{
			border: solid 1px transparent;
			transition: all .4s;
			border-bottom: solid 2px $white;
			background-color: transparent;
			padding: 10px;
			text-align: center;
			color: $white;
			&::placeholder{
				color: $white;
			}
			@include hover-focus{
				border-color: $white;
				outline: none;
			}
		}
		#default_mailing_list{
			display: none;
		}
		.btn{
			font-size: 9px;
			border-color: $white;
			color: $white;
			@include hover-focus{
				color: $black;
			}
			@include tablet-up{
				padding: 10px 20px;
			}
		}
	}
	.newsletter-notification{
		font-size: 9px;
		text-align: right;
		display: none;
		&.error{
			margin-top: 10px;
		}
	}
}

.desktop{
	display: none;
	@include  tablet-up{
		display: block;
	}
}

.mobile{
	@include  tablet-up{
		display: none;
	}
}

.laylo{
	width: 100%;
	iframe{
		width: 100%;
		min-height: 200px;
	}
}

header {
	position: relative;
	// background-color: $white;
	z-index: 3;
	padding: 20px 0;
	.social{
		display: none;
	}
	.newsletter{
		display: none;
	}
	.header-logo{
		height: 40px;
		margin: 0 auto;
		filter: invert(1)
	}
	@include tablet-up{
		.container{
			@include flex(row,space-between,center);
			.header-logo{
				flex: 1;
			}
			.social{
				display: flex;
				flex: 1;
				justify-content: flex-start;
			}
			.newsletter{
				display: block;
				flex: 1;
				form{
					justify-content: flex-end;
				}
			}
		}
	}
}

main {
	z-index: 4;
	position: relative;
	color: $white;
	.container{
		min-height: calc(100vh - 250px);
		@include flex(column,center,center);
		text-align: center;
		.copy{
			h3{
				font-size: 19px;
				margin-bottom: 10px;
				font-weight: 400;
				letter-spacing: 0.19px;
			}
			h2{
				font-size: 40px;
				margin-bottom: 10px;
				letter-spacing: 0.4px;
			}
		}
		.buttons{
			width: 100%;
			display:  grid;
			gap: 10px;
			.btn{
				color: $white;
				border-color: $white;
				@include hover-focus{
					color: $black;
				}
			}
			.disc{
				font-size: 10px;
				line-height: 1.4;
			}
		}
	}

	@include tablet-up{
		.container{
			min-height: calc(100vh- 138px);
			.copy{
				order: 1;
				min-height: calc(100vh- 224px);
				@include flex(column,center,center);
				h3{
					font-size: 24px;
					margin-bottom: 20px;
				}
				h2{
					font-size: 75px;
					margin-bottom: 20px;
				}
			}
			.buttons{
				order: 3;
				@include flex(row, space-between,initial);
				flex-wrap: wrap;
				gap: 20px;
				.btn{
					flex: 1;
				}
				.disc{
					width: 100%;
				}
			}
			.laylo{
				order: 2;
			}
		}
	}
}

.popup{
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: #dbdbdbab;
	padding: 60px 0;
	opacity: 0;
	pointer-events: none;
	transition: all .4s;
	&.active{
		opacity: 1;
		pointer-events: all;
	}
	.btn.popup-close{
		position: absolute;
		top: 0;
		right: 0;
		width: 60px;
		font-size: 30px;
		padding: 0;
		height: 60px;
		@include flex(row,center,center);
		background-color: $black;
		color: white;
	}
	.container{
		@include flex(row,center,center);
		height: calc(100vh - 120px);
		.wrapper{
			background-color: #E5E2E2;
			padding: 30px;
			border-radius: 10px;
			width: 700px;
			max-width: 100%;
			min-height: 300px;
			@include flex(column,center,center);

			section{
				opacity: 0;
				transition: all .4s;
				pointer-events: none;
				&.active{
					opacity: 1;
					pointer-events: all;
				}

				&.apple{
					position: absolute;
					form{
						div{
							@include flex(column,flex-start,initial);
							gap: 10px;
							@include tablet-up{
								flex-direction: row;
							}
						}
						label{
							display: none;
						}
						input{
							border: solid 1px transparent;
							transition: all .4s;
							border-bottom: solid 2px $black;
							background-color: transparent;
							padding: 10px;
							text-align: center;
							color: $black;
							&::placeholder{
								color: $black;
							}
							@include hover-focus{
								border-color: $black;
								outline: none;
							}
						}
						#default_mailing_list{
							display: none;
						}
						.btn{
							@include tablet-up{
								padding: 10px 20px;
							}
						}
					}
				}

				&.thank-you{
					position: absolute;
					text-align: center;
					h2{
						font-size: 28px;
						margin-bottom: 10px;
					}
					h3{
						font-weight: 400;
						font-size: 19px;
						a{
							text-decoration: underline;
						}
					}
				}

				&.exclusive{
					max-width: 100%;
					display: block;
					opacity: 0;
					pointer-events: none;
					&.active{
						opacity: 1;
						pointer-events: all;
					}
					.slider{
						height: 400px;
						opacity: 0;
						transition: all .4s;
						.slick-track {
							display: flex;
						}
						.slick-track .slick-slide {
							display: flex;
							height: auto;
							align-items: center;
							justify-content: center;
						}
						&.slick-initialized{
							opacity: 1;
						}
						img{
							max-height: 400px;
							width: 100%;
							object-fit: contain;
						}
						.slick-arrow{
							position: absolute;
							top: calc(50%);
							z-index: 99999;
							width: 20px;
							height: 20px;
							font-size: 20px;
							padding: 0;
							background-color: transparent;
							border: none;
							&.slick-prev{
								left: -20px;
							}
							&.slick-next{
								right: -20px;
							}
						}
					}
				}
			}
		}
	}
}

footer {
	position: relative;
	padding: 20px 0;
	z-index: 5;
	.container{
		@include flex(column,center,center);
		gap: 20px;
		.newsletter{
			.newsletter-notification{
				text-align: center;
			}
		}
		.copyright{
			font-size: 9px;
			text-align: center;
			color: $white;
			p{
				margin: 0;
				a{
					color: $white;
					@include hover-focus{
						text-decoration: underline;
					}
				}
			}
		}
	}
	@include tablet-up{
		background-color: transparent;
		.container{
			.social{
				display: none;
			}
			.newsletter{
				display: none;
			}
		}
	}
}